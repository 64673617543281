import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
import microfrontendLayout from "./microfrontend-layout.html";
import $ from "jquery";

$(document).ready(function () {
  updateContentDiv();
});

let toggle = true;
let smallScreen = false;

window.addEventListener(
  "@aedpaymain/mf-navbar/sidebarToggled",
  (event: CustomEvent) => {
    if (event.detail.isExpanded) {
      toggle = true;
    } else {
      toggle = false;
    }
    updateContentDiv();
  }
);

window.addEventListener(
  "@aedpaymain/mf-navbar/smallScreen",
  (event: CustomEvent) => {
    if (event.detail.smallScreen) {
      smallScreen = true;
    } else {
      smallScreen = false;
    }
    updateContentDiv();
    console.log("smallScreen: " + smallScreen);
  }
);

function updateContentDiv() {
  const contentDiv = $("#contentDiv");
  const contentDiv2 = $("deleteAlert-container");
  if (smallScreen) {
    contentDiv.addClass("smallscreen");
    contentDiv.removeClass("collapsed");
    contentDiv.removeClass("expanded");
    contentDiv2.css("margin-left", "0 !important");
    console.log("smallScreen:  its working");
  } else {
    if (toggle) {
      contentDiv.addClass("expanded");
      contentDiv.removeClass("collapsed");
      contentDiv2.css("margin-left", "200px !important");
    } else {
      contentDiv.addClass("collapsed");
      contentDiv.removeClass("expanded");
      contentDiv2.css("margin-left", "70px !important");
    }
  }
}

const routes = constructRoutes(microfrontendLayout);
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});
const layoutEngine = constructLayoutEngine({ routes, applications });

applications.forEach(registerApplication);
layoutEngine.activate();

start();
